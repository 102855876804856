import React, { useState, useRef, useEffect } from 'react';
import {
  Navbar,
  BannerLayout,
  Footer,
  queryKeys,
  getSignedUrl,
  getIdeaDetails,
  ScriptDownload,
  ArrowLink,
  OIDC,
  cookie
} from '@devportal/common';
import { useQuery, useMutation } from 'react-query';
import { Skeleton, CircularProgress, Tooltip } from '@mui/material';
import { WithNavLayout } from 'components';
import { saveAs } from 'file-saver';
import atob from 'atob';
import './index.css';
import { TbCopy } from 'react-icons/tb';
import ReactDOMServer from 'react-dom/server';

function IdeasHubDetailsPage({ match }) {
  const accessToken = cookie.get('accessToken');

  const loginHandler = async () => {
    window.localStorage.setItem('redirectPath', window.location.href);
    const url = await OIDC.signIn();
    window.location.replace(url);
  };
  if (!accessToken?.length) loginHandler();

  const mdConteinerRef: any = useRef();
  const [scrollIndex, setScrollIndex] = useState(0);
  const [activeFilePath, setActiveFilePath] = useState();

  const { data: idea, isLoading: isIdeaLoading } = useQuery(
    [
      queryKeys.IDEA_DETAIL,
      {
        ideaId: match?.params?.id,
      },
    ],
    getIdeaDetails,
    {
      onSuccess: (data) => {
        if (!data?.data) {
          loginHandler();
        }
      },
    }
  );

  const ideaDetails = idea?.data;
  const scriptsLength = ideaDetails?.attributes?.ideas_script_files?.length;
  const htmlString = atob(ideaDetails?.attributes?.ideas_readme_file?.file_path || '');

  const parser = new DOMParser();
  const documentFromString = parser.parseFromString(htmlString, 'text/html');
  const h2Tags = documentFromString.querySelectorAll('h2');
  const h2List: any = [];

  h2Tags.forEach((h2, i) => {
    h2List.push(h2.textContent);
    return h2.setAttribute('id', `scroll-tag-id-${i}`);
  });

  function createCopyButton(codeElement) {
    const btnContainer = document.createElement('div');
    btnContainer.className = 'w-full flex justify-end copy-button';
    const copyButton = document.createElement('button');
    copyButton.textContent = 'Copy Code';
    copyButton.className =
      'copy-button flex items-center text-white bg-sea-600 font-bold rounded-md p-2 mb-2 text-base cursor-pointer';
    const iconElement = ReactDOMServer.renderToString(<TbCopy className="text-xl mr-1" />);

    copyButton.innerHTML = iconElement + copyButton.textContent;
    btnContainer.appendChild(copyButton);
    codeElement.parentNode.insertBefore(btnContainer, codeElement);
  }

  const codeBlocks = documentFromString.querySelectorAll('code');
  codeBlocks.forEach(function (codeBlock) {
    createCopyButton(codeBlock);
  });

  const { mutate: mutateSignedUrl, isLoading: signinIsLoading } = useMutation({
    mutationFn: getSignedUrl,
    onSuccess: (data) => {
      saveAs(data?.data?.attributes?.signed_url, 'script_file');
    },
  });

  const onScriptDownload = ({ filePath }) => {
    setActiveFilePath(filePath);
    mutateSignedUrl({ queryKey: ['', { filePath }] });
  };

  useEffect(() => {
    const observer: any = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        const splittedId = entries[0].target.id.split('-');
        setScrollIndex(Number(splittedId[splittedId.length - 1]));
      }
    });
    if (mdConteinerRef?.current) {
      h2List.forEach((_, i) => {
        const id = `scroll-tag-id-${i}`;
        observer.observe(document.getElementById(id));
      });
    }
  }, [isIdeaLoading]);

  const copyCodeHandler = (event) => {
    if (event.target.classList.contains('copy-button')) {
      const codeElement = event.target.parentElement.nextElementSibling;
      const codeContent = codeElement.textContent;

      navigator.clipboard
        .writeText(codeContent)
        .then(() => {
          event.target.textContent = 'Copied!';
          setTimeout(() => {
            event.target.textContent = 'Copy Code';
          }, 1000);
        })
        .catch(function (err) {
          console.error('Error copying code:', err);
        });
    }
  };

  return (
    <WithNavLayout>
      <BannerLayout
        type="dark"
        breadcrumpList={[
          { name: 'Home', href: '/' },
          { name: 'IdeasHub', href: '/ideashub' },
          { name: ideaDetails?.attributes?.ideas_short_name, href: `/ideashub/${ideaDetails?.id}` },
        ]}
        title={ideaDetails?.attributes?.ideas_title}
        isDataLoading={isIdeaLoading}
        description={ideaDetails?.attributes?.ideas_description}
      ></BannerLayout>

      <div className="container mx-auto p-16">
        <div className="flex">
          <div className="pr-8 h-full w-full overflow-auto">
            {isIdeaLoading ? (
              <div className="justify-center flex">
                <CircularProgress size={80} />
              </div>
            ) : (
              <div className="h-[80vh]" id="md-details-container" ref={mdConteinerRef} onClick={copyCodeHandler}>
                <div dangerouslySetInnerHTML={{ __html: documentFromString?.documentElement?.innerHTML || '' }}></div>
              </div>
            )}
          </div>
          <div className="w-105">
            <div className="py-2 px-4" style={{ borderLeft: '1px solid #CDD7E5' }}>
              <div className="font-bold mb-4">ON THIS PAGE</div>
              {isIdeaLoading ? (
                Array(7)
                  .fill(2)
                  .map((item) => <Skeleton variant="rectangular" width="full" height={20} className="mt-2" />)
              ) : (
                <>
                  {h2List?.map((h2tag, i) => (
                    <div
                      className={`text-sm mb-2 cursor-pointer ${scrollIndex == i ? 'font-bold text-brand-300' : ''}`}
                      onClick={() => {
                        setScrollIndex(i);
                        document
                          .getElementById(`scroll-tag-id-${i}`)
                          ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
                      }}
                    >
                      {h2tag}
                    </div>
                  ))}
                </>
              )}
            </div>

            {isIdeaLoading || scriptsLength > 0 ? (
              <div className="my-16 rounded-md p-3 border-legacy-denim-light" style={{ border: '1px solid #CDD7E5' }}>
                <div className="font-bold mb-4">Files</div>
                {isIdeaLoading
                  ? Array(4)
                      .fill(2)
                      .map((item) => <Skeleton variant="rectangular" width="full" height={36} className="mt-2" />)
                  : ideaDetails?.attributes?.ideas_script_files?.map((file, i) => (
                      <ScriptDownload
                        className={`bg-sea-50  ${i !== scriptsLength - 1 ? 'mb-2' : ''}`}
                        btnClick={() => onScriptDownload({ filePath: file?.file_path })}
                        isLoading={activeFilePath === file?.file_path && signinIsLoading}
                        name={file?.file_name}
                        filesLen={scriptsLength}
                        link="#"
                      />
                    ))}
                    {ideaDetails?.attributes?.ideas_dependent_code_snippets?.length ? (
                  <p className="text-sm font-bold">Dependant Code Snippets</p>
                ) : null}
                {ideaDetails?.attributes?.ideas_dependent_code_snippets?.map((file, i) => (
                  <ScriptDownload
                    className={`bg-orange-50 mb-2`}
                    btnClick={() => onScriptDownload({ filePath: file?.file_path })}
                    isLoading={activeFilePath === file?.file_path && signinIsLoading}
                    name={file?.cs_name}
                    filesLen={scriptsLength}
                    link={`/code-samples/${file?.file_path?.split('/')[file?.file_path?.split('/')?.length - 1]}` || '#'}
                    isDownload={false}
                  />
                ))}
              </div>
            ) : 
            ideaDetails?.attributes?.ideas_dependent_code_snippets?.length > 0 && (<div className="my-16 rounded-md p-3 border-legacy-denim-light" style={{ border: '1px solid #CDD7E5' }}>
                  <p className="text-sm font-bold">Dependant Code Snippets</p>
                {ideaDetails?.attributes?.ideas_dependent_code_snippets?.map((file, i) => (
                  <ScriptDownload
                    className={`bg-orange-50 mb-2`}
                    btnClick={() => onScriptDownload({ filePath: file?.file_path })}
                    isLoading={activeFilePath === file?.file_path && signinIsLoading}
                    name={file?.cs_name}
                    filesLen={scriptsLength}
                    link={`/code-samples/${file?.file_path?.split('/')[file?.file_path?.split('/')?.length - 1]}` || '#'}
                    isDownload={false}
                  />
                ))}
              </div>)}
          </div>
        </div>
      </div>
      <Footer />
    </WithNavLayout>
  );
}
export { IdeasHubDetailsPage };
