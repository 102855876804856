import { cookie } from '@devportal/common';

let API_URL;
let AUTH_PROVIDER;

export async function loadApiDetails() {
  if (!API_URL || !AUTH_PROVIDER) {
    const { API_ENDPOINT, AUTH_PROVIDER: AUTH_PROVIDER_ENDPOINT }: any = await fetch('/envConfig.json').then((res) =>
      res.json()
    );

    API_URL = API_ENDPOINT;
    AUTH_PROVIDER = AUTH_PROVIDER_ENDPOINT;
  }
  return { API_URL, AUTH_PROVIDER };
}

export const fetchCodeSampleList = async ({ queryKey }) => {
  const { API_URL } = await loadApiDetails();
  const { filter, searchText, page, recordsPerPage } = queryKey[1];
  let queryParam = filter?.length ? `?filter_ids=${filter}` : '';

  if (searchText?.length) queryParam += `${queryParam?.length ? '&' : '?'}search_text=${searchText}`;
  if (recordsPerPage?.length) queryParam += `${queryParam?.length ? '&' : '?'}records_per_page=${recordsPerPage}`;
  if (page?.length && recordsPerPage?.length) {
    const offset = (Number(page) - 1) * recordsPerPage;
    queryParam += `${queryParam?.length ? '&' : '?'}offset=${String(offset)}`;
  }

  return (
    await fetch(`${API_URL}/code_samples${queryParam}`, {
      method: 'GET',
    })
  ).json();
};

export const getFilters = async ({ queryKey }) => {
  const { API_URL } = await loadApiDetails();
  const { filterPage } = queryKey[1];
  return (
    await fetch(`${API_URL}/filters?filter_page=${filterPage}`, {
      method: 'GET',
    })
  ).json();
};

export const getFeaturedCodeSamples = async () => {
  const { API_URL } = await loadApiDetails();
  return (
    await fetch(`${API_URL}/featured_code_samples`, {
      method: 'GET',
    })
  ).json();
};

export const getCodeSampleDetails = async ({ queryKey }) => {
  const { sampleId } = queryKey[1];
  const token = cookie.get('accessToken');
  const { API_URL } = await loadApiDetails();

  return (
    await fetch(`${API_URL}/code_samples/${sampleId}`, {
      method: 'GET',
      headers:
        token && token?.length
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
    })
  ).json();
};

export const getSignedUrl = async ({ queryKey }) => {
  const { filePath } = queryKey[1];
  const { API_URL } = await loadApiDetails();

  return (
    await fetch(`${API_URL}/signed_url`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          type: 'signed_url',
          attributes: {
            filePath,
          },
        },
      }),
    })
  ).json();
};

export const fetchIdeaList = async ({ queryKey }) => {
  const { API_URL } = await loadApiDetails();
  const { filter, searchText, page, recordsPerPage, has_scripts } = queryKey[1];
  let queryParam = filter?.length ? `?filter_ids=${filter}` : '';

  if (searchText?.length) queryParam += `${queryParam?.length ? '&' : '?'}search_text=${searchText}`;
  if (has_scripts?.length) queryParam += `${queryParam?.length ? '&' : '?'}has_scripts=${has_scripts}`;
  if (recordsPerPage?.length) queryParam += `${queryParam?.length ? '&' : '?'}records_per_page=${recordsPerPage}`;
  if (page?.length && recordsPerPage?.length) {
    const offset = (Number(page) - 1) * recordsPerPage;
    queryParam += `${queryParam?.length ? '&' : '?'}offset=${String(offset)}`;
  }

  return (
    await fetch(`${API_URL}/ideas${queryParam}`, {
      method: 'GET',
    })
  ).json();
};

export const getFeaturedIdeas = async () => {
  const { API_URL } = await loadApiDetails();
  return (
    await fetch(`${API_URL}/featured_ideas`, {
      method: 'GET',
    })
  ).json();
};

export const getIdeaDetails = async ({ queryKey }) => {
  const { ideaId } = queryKey[1];
  const token = cookie.get('accessToken');
  const { API_URL } = await loadApiDetails();

  return (
    await fetch(`${API_URL}/ideas/${ideaId}`, {
      method: 'GET',
      headers:
        token && token?.length
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
    })
  ).json();
};
